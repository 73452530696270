/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Flex, useMatchBreakpoints } from '@pancakeswap/uikit'
import { Dropdown, MenuProps } from 'antd'
import ArrowIcon from 'components/Svg/ArrowIcon'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import MenuIcon from './MenuIcon'

const MENU_HEIGHT = 84

const WrapMenu = styled.div`
  overflow: hidden;
  .menu-item {
    color: #fff;
    font-size: 16px;
    &:hover {
      background: linear-gradient(90deg, #00ebc9 0%, #00b6f6 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
    }
  }
  .nav {
    position: fixed;
    z-index: 20;
    background-color: #101116;
    border-bottom: 1px solid #ffffff1a;
    .logo {
      max-height: 50px;
    }
    padding: 17px 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    height: 84px;
    ${({ theme }) => theme.mediaQueries.sm} {
      height: 84px;
    }
    @media (max-width: 1025px) {
      padding: 17px 20px;
      .left-menu {
        gap: 10px;
      }
    }
    .ant-dropdown .ant-dropdown-menu,
    .ant-dropdown-menu-submenu .ant-dropdown-menu {
      background-color: #000000 !important;
    }
  }
  .historyMn {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    z-index: 10;
    padding: 7px 15px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.6);
    gap: 10px;
  }

  .dapp-btn {
    background: linear-gradient(90deg, #00ebc9 0%, #00b6f6 100%);
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    border-radius: 6px;
    color: #0e1420;
  }
`

const DropdownCustom = styled(Dropdown)`
  .ant-dropdown-menu {
    background-color: #000000 !important;
  }
`

const Container = styled.div``

const InnerBody = styled.div`
  width: 100%;
  // max-width: 1300px;
  padding: 0 16px;
  margin-top: ${MENU_HEIGHT}px;
  height: calc(100vh - ${MENU_HEIGHT}px);
  // background-image: url('/images/background/bg.png');
  // position: relative;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  // background-position: center;
  background-color: #101116;
  color: #fff;
  @media only screen and (max-width: 768px) {
    height: calc(100vh);
    padding-top: ${MENU_HEIGHT}px;
    margin-top: 0px;
  }
`
const Footer = styled.div`
  padding: 0px 10px 40px 10px;
  text-align: center;
  color: #686868;
`

export const StyledButton = styled(Button)`
  width: 148.16px;
  border-radius: 7px;
  color: #fff;
  padding: 2px;
  margin-left: 6px;
  @media only screen and (max-width: 768px) {
    width: 69.53px;
    height: 22.05px;
    opacity: 0px;
  }
`
const StyledConnect = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }
`
const Menu = ({ children }) => {
  const router = useRouter()
  const { isDesktop } = useMatchBreakpoints()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const closeMobileMenu = () => {
    if (!isDesktop) {
      setIsMenuOpen(false)
    }
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a href="https://beurl.app/ErRXavGQ" className="menu-item" rel="noreferrer">
          Introduction
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a href="https://beurl.app/SEetwcOs" target="_blank" className="menu-item" rel="noreferrer">
          Features
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a href="https://beurl.app/FdzUGUbX" target="_blank" className="menu-item" rel="noreferrer">
          Tokenomics
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <a href="https://beurl.app/NYUInUjV" target="_blank" className="menu-item" rel="noreferrer">
          Roadmap
        </a>
      ),
    },
    {
      key: '5',
      label: (
        <a
          href="https://beurl.app/MtDwPFWr"
          style={{
            color: '#FFF',
            cursor: 'pointer',
            fontSize: 16,
            background: 'linear-gradient(90deg, #00ebc9 0%, #00b6f6 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
          rel="noreferrer"
        >
          Go to DAPP
        </a>
      ),
    },
  ]

  return (
    <WrapMenu>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="nav">
        <div style={{ minWidth: 147, minHeight: 42, cursor: 'pointer' }}>
          <Image src="/images/logo/logo.png" alt="avb" width={147} height={42} />
        </div>

        {!isDesktop ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <Dropdown
              className="my-dropdown"
              trigger={['hover']}
              menu={{ items, theme: 'dark', style: { backgroundColor: '#000000D9', width: '150px' } }}
              placement="bottomCenter"
            >
              <MenuIcon />
            </Dropdown>
          </div>
        ) : (
          <>
            <Flex className="left-menu" alignItems="center" style={{ gap: 54 }}>
              <a href="https://beurl.app/ErRXavGQ" className="menu-item" rel="noreferrer">
                Introduction
              </a>
              <a href="https://beurl.app/SEetwcOs" target="_blank" className="menu-item" rel="noreferrer">
                Features
              </a>
              <a href="https://beurl.app/FdzUGUbX" target="_blank" className="menu-item" rel="noreferrer">
                Tokenomics
              </a>
              <a href="https://beurl.app/NYUInUjV" target="_blank" className="menu-item" rel="noreferrer">
                Roadmap
              </a>
            </Flex>
          </>
        )}
        {isDesktop && (
          <a rel="noreferrer" target="_blank" href="https://beurl.app/MtDwPFWr" className="dapp-btn">
            <div style={{ color: '#0E1420', marginRight: 4 }}>Go To DApp</div>
            <ArrowIcon />
          </a>
        )}
      </div>
      <InnerBody>{children}</InnerBody>
      {/* <Footer>Copyright © 2024 Udefi. All rights reserved</Footer> */}
    </WrapMenu>
  )
}

export default Menu
